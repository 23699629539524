import _ from "lodash";
import {numericFormatter} from "react-number-format";

export const caseInsentitiveSearch = (word, searchValue) => {
    return word.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
}

export const searchPricings = (data, searchValue) => {
    if (searchValue.length > 0) {
        const filtered = {}
        _.map(data, (models, year) => {
            _.map(models, (model) => {
                let shouldPickModel = false
                if (caseInsentitiveSearch(model.name, searchValue)) {
                    shouldPickModel = true
                }
                let filteredTrims = []
                let shouldPickTrim = false
                _.map(model.trims, (trim) => {
                    if (caseInsentitiveSearch(trim.name, searchValue)) {
                        shouldPickTrim = true
                    }
                    _.map(trim.style_descriptions, (sd) => {
                        if (caseInsentitiveSearch(sd.name, searchValue)) {
                            shouldPickTrim = true
                        }
                    })

                    if (shouldPickTrim) {
                        filteredTrims.push(trim)
                    }
                })

                if (shouldPickModel) {
                    filtered[year] ||= []
                    filtered[year].push(model)
                }
                if (!shouldPickModel && shouldPickTrim) {
                    filtered[year] ||= []
                    let newModel = model
                    newModel.trims = filteredTrims
                    filtered[year].push(newModel)
                }
            })
        })

        return filtered
    } else {
        return data
    }
}

export const buildTrimAndStyleDescriptionName = (trimName, styleDescriptionName) => {
    const newName = trimName.split(" ")
    const split = styleDescriptionName.split(" ")
    split.forEach((word) => {
        if (!trimName.includes(word)) {
            newName.push(word)
        }
    })
    return newName.join(" ")
}

export const buildStyleDescriptionName = (trimName, styleDescriptionName) => {
    const newName = []
    const split = styleDescriptionName.replace("(Natl)", "").split(" ")
    split.forEach((word) => {
        if (!trimName.includes(word)) {
            newName.push(word)
        }
    })
    return newName.join(" ").trim()
}

export const parseGoogleAddressData = (address_components = []) => {
    let address_line_one = ''
    let zipcode = ''
    let state = ''
    let county = ''
    let city = ''
    for (const component of address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address_line_one = `${component.long_name} ${address_line_one}`;
                break;
            }

            case "route": {
                address_line_one += component.short_name;
                break;
            }

            case "postal_code": {
                zipcode = `${component.long_name}${zipcode}`;
                break;
            }
            case "sublocality_level_1": {
                city = `${component.long_name}${zipcode}`;
                break;
            }

            case "locality":
                city = component.long_name;
                break;

            case "administrative_area_level_1": {
                state = component.short_name;
                break;
            }
            case "administrative_area_level_2": {
                county = component.short_name;
                break;
            }
        }
    }

    return {
        address_line_one,
        zipcode,
        state,
        county,
        city
    }
}

export const parsedAddressObjectToString = (addressObject) => {
    if (!addressObject) return '';

    const {
        address_line_one,
        city,
        state,
        zipcode
    } = addressObject
    return `${address_line_one} ${city} ${state} ${zipcode}`

}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

