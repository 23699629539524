import React from 'react'
import classnames from 'classnames';

import QuoteHelper from "../../utils/QuoteHelper";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {useState} from "react";
import {SkeletonLoader} from "../../components/SkeletonLoader";
import {useModalContext} from "../../contexts/ModalContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useDeskingContext} from "../../contexts/DeskingContext";

const DeskingTableRowCell = ( { dealId, quote, isSelected, isPurchase } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const {
        createDeal,
        createDealResponse,
        updateDeal,
        updateDealResponse,
        deskingState,
        onSave,
    } = useDeskingContext()

    const { setIsDeskingModalOpen } = useModalContext()
    const [selected, setSelected] = useState(false)

    const saveQuote = (quote) => {
        setSelected(true)
        const helper = new QuoteHelper(null, quote)

        const updateData = {
            selected_quote: quote,
            term: helper.term(),
            mileage: helper.annualMileage(),
            _no_update: true
        }
        if (dealId) {
            return updateDeal(dealId, updateData).then((data) => {
                const updateData = data?.data
                onSave && onSave(updateData)
                setSelected(false)
                setIsDeskingModalOpen(null)
            }).catch(() => {
                setSelected(false)
            })
        } else {
            return createDeal({...deskingState, ...updateData, _no_update: true}).then((data) => {
                const createData = data?.data
                onSave && onSave(createData)
                setSelected(false)
                setIsDeskingModalOpen(null)
            }).catch(() => {
                setSelected(false)
            })
        }


    }

    if (!quote) {
        return <td className={'disabled'}></td>
    }
    const helper = new QuoteHelper(null, quote)
    const isLoading = (createDealResponse?.loading || updateDealResponse?.loading)
    const isLoadingAndSelected = isLoading && selected

    return (
        <SkeletonLoader loading={isLoadingAndSelected}>
            <td
                onClick={() => {
                    if (!isLoading) {
                        saveQuote(quote)
                    }
                }}
                className={'quote'}
            >
                <div
                    className={classnames('info', {
                        selected: isSelected,
                        isPurchase: isPurchase,
                    })}
                >
                    {
                        !isTabletOrMobile && <DescriptionOutlinedIcon/>
                    }
                    <div className={'info-payment'}>
                        <Typography fontWeight={700} variant="div">
                            ${isPurchase ? helper.monthlyPayment() : helper.advancePayment()}
                        </Typography>
                        {
                            !isTabletOrMobile &&
                            <Typography className={'month'}>
                                /Month
                            </Typography>
                        }
                    </div>
                </div>

            </td>
        </SkeletonLoader>
    )
}

export const DeskingTableRow = ( { term, quotes, lenders, dealId, dealState, onSave } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const helper = new QuoteHelper(dealState)

    const isSelected = (term, lender) => {
        return Boolean(
            dealState &&
            String(dealState.term) === String(term) &&
            helper.lender() === lender
        )
    }

    return (
        <tr>
            <td className={'term'}>
                <Typography
                    fontWeight={400}
                    fontSize={20}
                    variant="div"
                    gutterBottom>
                    {term}
                </Typography>
                {
                    !isTabletOrMobile &&
                    <Typography fontWeight={400} variant="subtitle1" gutterBottom>
                        MONTHS
                    </Typography>
                }
            </td>
            {
                lenders.map( (lender, index) => {
                    const quote = quotes[lender]
                    return <DeskingTableRowCell
                        isPurchase={dealState.is_purchase}
                        dealId={dealId}
                        quote={quote}
                        isSelected={isSelected(term, lender)}
                        key={index}
                        onSave={onSave}
                    />
                })
            }
        </tr>
    )
}