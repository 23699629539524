import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const TaxInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    const taxes = [
        helper.buildFee("Cashdown Tax", '41326'),
        helper.buildFee("County Discretionary Surtax", '41139'),
    ]
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Tax Information</Text>
            </View>

            <View style={baseStyles.flexBetween}>
                <Text>Tax Rate</Text>
                <Text>{helper.taxRate()}%</Text>
            </View>

            {/*<View style={{...baseStyles.flexBetween}}>*/}
            {/*    <Text*/}
            {/*        style={{width: '40%', ...baseStyles.bold, ...baseStyles.alignEnd}}*/}
            {/*    >*/}
            {/*        Type*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{width: '25%', ...baseStyles.bold, ...baseStyles.alignEnd}}*/}
            {/*    >*/}
            {/*        Paid*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{width: '24%', ...baseStyles.bold, ...baseStyles.alignEnd}}*/}
            {/*    >*/}
            {/*        Rate*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{width: '10%', ...baseStyles.bold, ...baseStyles.alignEnd}}*/}
            {/*    >*/}
            {/*        Amt*/}
            {/*    </Text>*/}
            {/*</View>*/}
            {/*{*/}
            {/*    taxes.map((t) => {*/}
            {/*        return (*/}
            {/*            <View*/}
            {/*                key={t.Name}*/}
            {/*                style={{...baseStyles.flexBetween}}*/}
            {/*            >*/}
            {/*                <Text*/}
            {/*                    style={{ width: '50%'}}*/}
            {/*                >*/}
            {/*                    {t.Name}*/}
            {/*                </Text>*/}
            {/*                <Text*/}
            {/*                    style={{ width: '20%'}}*/}
            {/*                >*/}
            {/*                    {t.Type}*/}
            {/*                </Text>*/}
            {/*                <Text*/}
            {/*                    style={{ width: '20%'}}*/}
            {/*                >*/}
            {/*                    {t.TaxRate}*/}
            {/*                </Text>*/}
            {/*                <Text*/}
            {/*                    style={{ width: '10%'}}*/}
            {/*                >*/}
            {/*                    {t.Amount.toDollarFormat()}*/}
            {/*                </Text>*/}
            {/*           </View>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
            {/*<View*/}
            {/*    style={{...baseStyles.flexBetween}}*/}
            {/*>*/}
            {/*    <Text*/}
            {/*        style={{ width: '50%'}}*/}
            {/*    >*/}
            {/*        Monthly Tax*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{ width: '20%'}}*/}
            {/*    >*/}
            {/*        Capped*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{ width: '20%'}}*/}
            {/*    >*/}
            {/*        { helper.taxRate() }*/}
            {/*    </Text>*/}
            {/*    <Text*/}
            {/*        style={{ width: '10%'}}*/}
            {/*    >*/}
            {/*        { helper.montlyUseTax().toDollarFormat() }*/}
            {/*    </Text>*/}
            {/*</View>*/}
        </View>
    )
}