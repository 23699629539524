import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {baseStyles} from "../styles";
import {LenderInfoPDF} from "./LenderInfoPDF";
import {LocationInfoPDF} from "./LocationInfoPDF";

export const VehicleInfoPDF = ( { deal } ) => {
    const car = deal.car_detail

    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Vehicle Info</Text>
            </View>

            <View style={{padding: "5px 10px"}}>
                <Text style={{...baseStyles.boldBlack}}>
                    {car.year}  {car.make}  {car.model}  {car.trim}  {car.style_description}
                </Text>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <LenderInfoPDF deal={deal}/>
                    <LocationInfoPDF deal={deal}/>
                </View>
            </View>
        </View>
    )
}