import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const RebatesBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    const rebates = helper.appliedRebates()

    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Rebates Breakdown</Text>
            </View>

            <View style={{...baseStyles.flexBetween}}>
                <Text
                    style={{...baseStyles.bold, width: '90%'}}
                >
                    Name
                </Text>

                <Text
                    style={{...baseStyles.bold, ...baseStyles.alignEnd, width: '10%', textAlign: 'end'}}
                >
                    Amt
                </Text>
            </View>
            <View>
                {
                    rebates.map((t) => {
                        return (
                            <View
                                key={t.TitleId}
                                style={{...baseStyles.flexBetween}}
                            >
                                <Text
                                    style={{width: '90%'}}
                                >
                                    {t.Name}
                                </Text>
                                <Text
                                    style={{width: '10%', textAlign: 'end'}}
                                >
                                    {(t.Amount * -1).toDollarFormat()}
                                </Text>
                            </View>
                        )
                    })
                }
                <View
                    style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}
                >
                    <Text
                        style={{width: '90%'}}
                    >
                        Total Rebates
                    </Text>
                    <Text
                        style={{width: '10%', textAlign: 'end'}}
                    >
                        {helper.totalRebatesAmount().toDollarFormat()}
                    </Text>
                </View>
            </View>
        </View>
    )
}