import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerProfitInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Profit Information</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text style={baseStyles.flex}>
                        Vehicle Profit
                    </Text>
                    <Text>{helper.vehicleProfit().toDollarFormat()}</Text>
                </View>
            </View>
        </View>
    )
}