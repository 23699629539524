import {StyleSheet, Font} from "@react-pdf/renderer";

import Roboto from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoItalic from '../../../assets/fonts/Roboto/Roboto-Italic.ttf';
import RobotoBold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoBoldItalic from '../../../assets/fonts/Roboto/Roboto-BoldItalic.ttf';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: Roboto,
        },
        {
            src: RobotoBold,
            fontWeight: 'bold',
        },
        {
            src: RobotoBold,
            fontWeight: '400',
        },
        {
            src: RobotoBold,
            fontWeight: '500',
        },
        {
            src: RobotoBold,
            fontWeight: '600',
        },
        {
            src: RobotoItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
        },
        {
            src: RobotoBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
    format: 'truetype',
});

export const baseStyles = StyleSheet.create({
    page: {
        padding: '40px',
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: '300',
    },
    boldBlack: {
        color: "#000000",
        fontWeight: '600',
    },
    table: {
        width: '100%',
        marginBottom: '10px',
        border: '1px solid #E8E8E8',
        backgroundColor: '#F5F5F5',
        color: '#666666',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
    },
    marginRight: {
        marginRight: '20px'
    },
    topLine: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '10px'
    },
    header: {
        borderTop: '1px solid #D3D3D3',
        borderBottom: '1px solid #D3D3D3',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    tableHeader: {
        fontWeight: '700',
        display: 'flex',
        color: '#FFFFFF',
        padding: '5px 0px 5px 20px',
        textTransform: 'uppercase',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
    },
    bold: {
        fontWeight: '700',
    },
    boldTopBorder: {
        fontWeight: '700',
        borderTop: '1px solid #D3D3D3',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        padding: '3px 10px'
    },
    tablesLayout: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        padding: '3px 10px',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        flexGrow: 1,
    },
    bottomBorder: {
        borderBottom: '2px solid black',
    },
    marginTop: {
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
    },
    flexGrow: {
        flexGrow: 1,
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    alignEnd: {
        alignSelf: 'end',
        display: 'flex',
        alignItems: 'flex-end'
    },
    tablePadding: {
        // padding: '3px 10px'
    },
    leftColumn: {
        marginRight: '2px'
    },
    rightColumn: {
        marginLeft: '2px'
    },
    sumRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
        fontWeight: '700',
        borderTop: '1px solid #D3D3D3'
    },
    vehicleInfoLabel: {
        fontWeight: '300',
        textTransform: "uppercase",
        fontSize: '8px',
        marginRight: '10px',
        alignItems: 'center',
    },
    vehicleInfo: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '10px',
        // marginRight: '10px'
    },
    vehicleInfoContainer: {
        // width: '33%',
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center'
    }
});
