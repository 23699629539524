import {baseStyles} from "../styles";
import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {parsedAddressObjectToString} from "../../../../utils/utils";

export const HeaderPDF = ({ deal, pdfName, companyName, customerAddress, customerName }) => {
    if (!deal || ! deal.id || !companyName) return null;

    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{
            ...baseStyles.flexBetween,
            alignItems: 'center',
            alignContent: 'center',
        }}>
            <View
                style={{
                    fontSize: '30px',
                    fontWeight: '400',
                    textAlign: 'left',
                    color: pageColor,
                    textTransform: 'uppercase',
                }}
            >
                <Text>{companyName}</Text>
            </View>
            <View>
                <Text
                    style={{
                        fontSize: '10px',
                        color: '#666666',
                        textDecoration: 'underline',
                    }}
                >
                    {pdfName} - {new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}
                </Text>
                <Text
                    style={{
                        fontSize: '10px',
                        color: '#666666',
                    }}
                >
                    {customerName}
                </Text>
                <Text
                    style={{
                        fontSize: '10px',
                        color: '#666666',
                    }}
                >
                    {parsedAddressObjectToString(customerAddress)}
                </Text>
            </View>
        </View>
    )
}