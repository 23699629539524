import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";
import QuoteHelper from "../../../../utils/QuoteHelper";

export const AmountDueAtSigningPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Amount Due at Signing</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>1st Month Payment</Text>
                    <Text>{helper.advancePayment().toDollarFormat() }</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Aquisition Fee</Text>
                    <Text>{helper.acquisitionFeeAmount().toDollarFormat()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Fees & Insurance</Text>
                    <Text>{helper.feesAndInsurance().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Upfront Taxes</Text>
                    <Text>
                        {helper.upfrontTaxes().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Cap Reduction (Cash)</Text>
                    <Text>
                        {helper.capReductionCash().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text
                    >
                        Total Inception Fees
                    </Text>
                    <Text>
                        {helper.totalInceptionFees().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Trade as Cap Reduction</Text>
                    <Text>
                        {helper.tradeAsCapReduction().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Rebate as Cap Reduction</Text>
                    <Text>
                        {helper.rebateAsCapReduction().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text
                    >
                        Total Drive Off
                    </Text>
                    <Text
                    >{helper.totalDriveOff().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Cash as Drive Off</Text>
                    <Text>
                        {helper.driveOffFeeAmount().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Trade as Drive Off</Text>
                    <Text>
                        {helper.tradeAsDriveOffFeeAmount().toDollarFormat()}
                    </Text>
                </View>

                {/*<View style={{...baseStyles.flexBetween}}>*/}
                {/*    <Text>Rebate as Drive Off</Text>*/}
                {/*    <Text>*/}
                {/*        {helper.rebateAsDriveOffAmount().toDollarFormat()}*/}
                {/*    </Text>*/}
                {/*</View>*/}

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Inception Fees (Added to Cap)</Text>
                    <Text>
                        {helper.inceptionFeesAddedToCap().toDollarFormat()}
                    </Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Total Cash on Delivery</Text>
                    <Text>
                        {helper.amountDueOnDelivery().toDollarFormat()}
                    </Text>
                </View>
            </View>
        </View>
    )
}