import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useAuthContext} from "../../contexts/AuthContext";
import classnames from 'classnames'
import {toast} from "react-toastify";

import './LoginPage.scss'
import Stack from "@mui/material/Stack";
import {PasswordInput} from "../../components/PasswordInput";
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../assets/pngs/truedesking.png'
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";

export const Loginpage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { login } = useAuthContext()
    const [emailOrUsername, setEmailOrUsername] = useState('')
    const [password, setPassword] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()
        return login(emailOrUsername.toLowerCase(), password)
            .catch((err) => {
                toast.error(err?.response?.data?.detail)
            })
    }

    const isDisabled = !emailOrUsername || !password

    return (
        <Box id="LoginPage">
            <div className={'container'}>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        className={'input-group'}
                    >
                        <Stack
                            justifyContent="center"
                            style={{ marginBottom: '30px'}}
                        >
                            <img src={logo} alt="Logo" style={{ width: isTabletOrMobile ? '290px' : '350px'}} />
                        </Stack>
                        <input
                            className={'login-page_input'}
                            id="outlined-email-input"
                            label="Email"
                            type="text"
                            autoComplete="current-email"
                            onChange={(e) => setEmailOrUsername(e.target.value)}
                            defaultValue={emailOrUsername}
                            placeholder={'Email or Username'}
                        />
                        <PasswordInput
                            className={'login-page_input'}
                            id="outlined-password-input"
                            label="Password"
                            type="password"
                            placeholder={'Password'}
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                            defaultValue={password}
                        />
                        <Button
                            type="submit"
                            disabled={isDisabled}
                            className={classnames('login-button', {
                                disabled: isDisabled
                            })}
                        >
                            Sign in <LoginIcon style={{ marginLeft: '3px' }}/>
                        </Button>
                    </Stack>
                </Box>
            </div>
        </Box>
    )
}