import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const ResidualInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}} wrap={false}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Residual Information</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>
                        MSRP
                    </Text>
                    <Text>{helper.msrp().toDollarFormat()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>MRM</Text>
                    <Text>{helper.mrm()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Residual Percentage</Text>
                    <Text>{helper.residualPercentage()}%</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Annual Mileage</Text>
                    <Text>{helper.annualMileage()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Total Residual</Text>
                    <Text>{helper.totalResidual().toDollarFormat()}</Text>
                </View>
            </View>
        </View>
    )
}