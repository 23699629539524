import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseCustomerPaymentInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Payment Information</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Cash Due on Delivery</Text>
                    <Text>{helper.cashDueOnDelivery().toDollarFormat()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Payment</Text>
                    <Text>{helper.payment().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Annual Percentage Rate (APR)</Text>
                    <Text>{helper.apr()}%</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Term:</Text>
                    <Text>{helper.loanTerm()}</Text>
                </View>
            </View>
        </View>
    )
}