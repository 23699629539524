import React from "react";
import './App.scss';
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import {routes} from "./routes";

import 'react-toastify/dist/ReactToastify.css';
import {numericFormatter} from "react-number-format";

if (!String.prototype.toDollarFormat) {
  String.prototype.toDollarFormat = function() {
    return numericFormatter(this, {prefix: "$", thousandSeparator: ","})
  };
}

if (!Number.prototype.toDollarFormat) {
  Number.prototype.toDollarFormat = function() {
    return String(this).toDollarFormat()
  };
}

function App() {
  return <RouterProvider router={createBrowserRouter(routes)}/>
}

export default App;
