import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {DealerDisclosurePDF} from "./DisclosurePDFs/Dealer/Lease/DealerDisclosurePDF";
import {useUserContext} from "../../contexts/UserContext";
import {PurchaseDealerDisclosurePDF} from "./DisclosurePDFs/Dealer/Purchase/PurchaseDealerDisclosurePDF";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {PDFViewer} from "@react-pdf/renderer";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px',
    width: '60vw',
    height: '90vh',
    outline: 'none',
};

export const DealerPDFModal = ( { isOpen, setIsOpen, dealState, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { profileState } = useUserContext()

    if (!dealState) return null;

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className={''}
                style={{
                    ...style,
                    padding: '30px 20px'
                }}
            >
                {
                    isTabletOrMobile &&
                    <span
                        onClick={() => setIsOpen(null)}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '27px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                                <CloseIcon/> Close
                            </span>
                }


                <Stack
                    direction="column"
                    // justifyContent="space-between"
                    alignItems="left"
                    className={''}
                    style={{
                        height: '100%'
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className={'DealRebateModal-name'}
                    >
                        Dealer Disclosure PDF
                    </Typography>
                    <Divider className={'divider'} />

                    <Box
                        style={{
                            width: '100%',
                            height: '100%',
                            marginTop: '20px',
                        }}
                    >
                        <PDFViewer
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            {
                                !dealState.is_purchase ?
                                    <DealerDisclosurePDF
                                        deal={dealState}
                                        companyName={profileState?.account_name}
                                    /> :
                                    <PurchaseDealerDisclosurePDF
                                        deal={dealState}
                                        companyName={profileState?.account_name}
                                    />
                            }
                        </PDFViewer>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    )
}