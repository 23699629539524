import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import {DeskingPage} from "../DeskingPage/DeskingPage";
import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {DeskingContextProvider} from "../../contexts/DeskingContext";


export const DeskingModal = ( { id, isOpen, setIsOpen, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'DeskingModal'}>
                {
                    isTabletOrMobile &&
                    <span
                        onClick={() => setIsOpen(null)}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '27px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                                <CloseIcon/> Close
                            </span>
                }
                <DeskingContextProvider {...props}>
                    <DeskingPage id={id} {...props}/>
                </DeskingContextProvider>
            </Box>
        </Modal>
    )
}