import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const PaymentInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Payment Information</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Base Monthly Payment</Text>
                    <Text>{helper.basePayment().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Total Monthly Payment</Text>
                    <Text>{helper.regularPayment().toDollarFormat()}</Text>
                </View>
            </View>
        </View>
    )
}