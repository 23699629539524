import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerDownPaymentBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Down Payment Breakdown</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Cash Down Payment</Text>
                    <Text>{helper.cashDueOnDelivery().toDollarFormat()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Customer Rebates</Text>
                    <Text>{helper.totalRebatesAmount().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Total Down Payment</Text>
                    <Text>{helper.totalDownPayment().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.boldTopBorder, ...baseStyles.flexBetween}}>
                    <Text>Sales Subtotal</Text>
                    <Text>{helper.salesSubtotal().toDollarFormat()}</Text>
                </View>

            </View>
        </View>
    )
}