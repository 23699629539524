import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerPriceBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)
    const pageColor = deal.is_purchase ? '#1BA897' : '#2B8EEA'

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={{...baseStyles.tableHeader, backgroundColor: pageColor}}>
                <Text>Price Breakdown</Text>
            </View>

            <View>
                <View style={baseStyles.flexBetween}>
                    <Text>Vehicle Cost</Text>
                    <Text>{deal.cost}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Vehicle Profit</Text>
                    <Text>{helper.vehicleProfit().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Customer Rebate (Disc)</Text>
                    <Text>{helper.totalRebatesAmount().toDollarFormat()}</Text>
                </View>

                <View style={{...baseStyles.boldTopBorder, ...baseStyles.flexBetween}}>
                    <Text>Total Cash Price</Text>
                    <Text>{helper.totalCashPrice().toDollarFormat()}</Text>
                </View>
            </View>
        </View>
    )
}