import React, {useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import classnames from "classnames";
import {PurchaseCustomerDisclosurePDF} from "./DisclosurePDFs/Customer/Purchase/PurchaseCustomerDisclosurePDF";
import {CustomerDisclosurePDF} from "./DisclosurePDFs/Customer/Lease/CustomerDisclosurePDF";
import {useUserContext} from "../../contexts/UserContext";
import {parseGoogleAddressData} from "../../utils/utils";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Autocomplete from "react-google-autocomplete";
import {PDFViewer} from "@react-pdf/renderer";
import Input from "@mui/material/Input";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px',
    width: '60vw',
    height: '90vh',
    outline: 'none',
};

export const CustomerPDFModal = ( { isOpen, setIsOpen, dealState, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { profileState } = useUserContext()

    const [customerName, setCustomerName] = useState('')
    const [customerAddress, setCustomerAddress] = useState(null)

    if (!dealState) return null;

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className={''}
                style={{
                    ...style,
                    padding: '30px 20px'
                }}
            >
                {
                    isTabletOrMobile &&
                    <span
                        onClick={() => setIsOpen(null)}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '27px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                                <CloseIcon/> Close
                            </span>
                }


                <Stack
                    direction="column"
                    // justifyContent="space-between"
                    alignItems="left"
                    className={''}
                    style={{
                        height: '100%'
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className={'DealRebateModal-name'}
                    >
                        Customer Disclosure PDF
                    </Typography>
                    <Divider className={'divider'} />

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        className={''}
                    >
                        <div>
                            <Input
                                className={'CustomerPDF-input'}
                                type="text"
                                variant="standard"
                                value={customerName}
                                placeholder={'Customer Name'}
                                onChange={(e) => setCustomerName(e.target.value)}
                        />
                        </div>

                        <div>
                            <Input
                                className={'CustomerPDF-input'}
                                placeholder={'Customer Address'}
                                variant="standard"
                                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                                    <Autocomplete
                                        variant="standard"
                                        className={classnames('', {
                                            disabled: !customerAddress
                                        })}
                                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                        {...props}
                                        onPlaceSelected={(place) => {
                                            setCustomerAddress(parseGoogleAddressData(place.address_components))
                                        }}
                                        options={{
                                            componentRestrictions: {country: ["us", "ca"]},
                                            fields: ["address_components", "geometry"],
                                            types: ["address"],
                                        }}
                                    />
                                )}
                            />
                        </div>

                    </Stack>

                    <Box
                        style={{
                            width: '100%',
                            height: '100%',
                            marginTop: '20px',
                        }}
                    >
                        <PDFViewer
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            {
                                !dealState.is_purchase ?
                                    <CustomerDisclosurePDF
                                        customerName={customerName}
                                        customerAddress={customerAddress}
                                        deal={dealState}
                                        companyName={profileState?.account_name}
                                    /> :
                                    <PurchaseCustomerDisclosurePDF
                                        customerName={customerName}
                                        customerAddress={customerAddress}
                                        deal={dealState}
                                        companyName={profileState?.account_name}
                                    />
                            }
                        </PDFViewer>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    )
}